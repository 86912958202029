<template>
  <div>
<!--    pc端-->
    <div v-if="!isMobile" class="top">
      <div class="top-nav">
        <div class="header">
          <!-- logo-->
          <div class="header-logo" @click="handleLogoClick">
            <img src="../../assets/image/logo/logo.svg" alt="logo" class="logo">
          </div>
          <!--nav-->
          <div class="nav">
            <el-menu :default-active="activeIndex" style="border: 0" background-color="#102867" text-color="#fff" active-text-color="#fff"
                     class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item class="header-title" index="/">{{ $t('home') }}</el-menu-item>
              <el-menu-item class="header-title" index="/transfer">跨境转运</el-menu-item>
              <el-submenu index="3" class="submenu-bg">
                <template slot="title"><div class="header-title">产业带出海</div></template>
                <el-menu-item class="header-title" index="/prophet"><div>先知</div></el-menu-item>
                <el-menu-item class="header-title" index="/talents"><div>雏鹰人才</div></el-menu-item>
                <el-menu-item class="header-title" index="/corotong"><div>科若通</div></el-menu-item>
                <el-menu-item class="header-title" index="/pilotage-plan"><div>领航计划</div></el-menu-item>
              </el-submenu>
              <el-menu-item class="header-title" index="/cooperation-channel">渠道合作</el-menu-item>
              <el-menu-item class="header-title" index="/about">关于我们</el-menu-item>
              <el-menu-item class="header-title" index="/contact">联系我们</el-menu-item>
            </el-menu>
          </div>
          <!--中英文切换-->
          <div class="i18n">
            <img src="../../assets/image/home/icon-language.png" alt="icon" class="icon-language">
            <div class="ChannelSelected">
              <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ languageStr }}<i class="el-icon-arrow-down" style="color: #999;margin-left: 5px;"></i>
            </span>
                <el-dropdown-menu slot="dropdown" >
                  <el-dropdown-item command="zh">中文</el-dropdown-item>
                  <el-dropdown-item command="en">English</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>

      </div>
    </div>
<!--    移动端-->
    <div v-else class="m-top">
      <div class="m-header">
        <!-- logo-->
        <div class="m-header-logo" @click="handleLogoClick">
          <img src="../../assets/image/logo/logo.svg" alt="logo" class="logo">
        </div>
        <!-- 设置-->
        <div class="set" @click="menuClick">
          <img :src="menuImgSrc[menuShow ? 1 : 0]" alt="打开" class="">
        </div>
      </div>
      <!--導航彈框-->
      <div class="fixed-nav" v-show="menuShow">
        <div class="bj" @click="navClose"></div>
        <div class="nav-content">
          <el-menu :default-active="activeIndex" style="border: 0" background-color="#102867" text-color="#fff" active-text-color="#fff"
                   class="el-menu-demo" @select="handleSelect">
            <el-menu-item class="m-header-title" index="/">{{ $t('home') }}</el-menu-item>
            <el-menu-item class="m-header-title" index="/transfer">跨境转运</el-menu-item>
            <el-submenu index="3" class="submenu-bg">
              <template slot="title"><div class="m-header-title">产业带出海</div></template>
              <el-menu-item class="m-header-title" index="/prophet"><div>先知</div></el-menu-item>
              <el-menu-item class="m-header-title" index="/talents"><div>雏鹰人才</div></el-menu-item>
              <el-menu-item class="m-header-title" index="/corotong"><div>科若通</div></el-menu-item>
              <el-menu-item class="m-header-title" index="/pilotage-plan"><div>领航计划</div></el-menu-item>
            </el-submenu>
            <el-menu-item class="m-header-title" index="/cooperation-channel">渠道合作</el-menu-item>
            <el-menu-item class="m-header-title" index="/about">关于我们</el-menu-item>
            <el-menu-item class="m-header-title" index="/contact">联系我们</el-menu-item>
          </el-menu>
        </div>
      </div>
      <!--banner-->
    </div>
  </div>
</template>

<script>
import { isMobileDevice } from "../../utils/detectDevice";
export default {
  name: "topNav",
  data() {
    return {
      isMobile: isMobileDevice(),
      menuImgSrc: [require('../../assets/mobile-image/69.png'), require('../../assets/mobile-image/X191.png')],
      menuShow: false,
      activeIndex: this.$route.path,
      languageStr: '',
      t: this.$i18n.locale,
      languageList: [
        {
          key: 'cn',
          label: '简体中文'
        },
        {
          key: 'en',
          label: 'English'
        }
      ]
    }
  },
  created () {
    this.languageStr = this.$i18n.locale === 'zh' ? '简体中文' : 'English'
    console.log(this.languageStr)
  },
  methods: {
    // pc
    handleLogoClick() {
      this.$router.push('/');
      this.activeIndex = '/';
    },
    handleSelect(index){
      this.$router.push(index);
      this.activeIndex = index;
      // 移动端
      this.menuShow = false
    },
    // 根据下拉框的中被选中的值切换语言
    handleCommand(command) {
      if (command === 'zh') {
        this.lang = '中文'
      } else {
        this.lang = 'English'
      }
      console.log(command,'333')
      this.$i18n.locale = command
      sessionStorage.setItem('lang', command)
      window.location.reload()
    },
    // 移动端
    menuClick() {
      this.menuShow = !this.menuShow
    },
    navClose() {
      this.menuShow = false
    },
  },

}
</script>
<!--pc端-->
<style scoped>
.top {
  height: 64px;
}
 .top-nav {
   width: 100%;
   height: 64px;
   background-color: #102867;
   display: flex;
   position: fixed;
   z-index: 999;
 }
 .header {
   width: 1920px;
   display: flex;
   justify-content: space-between;
   margin: 0 auto;
   overflow: hidden;
 }
 .nav ul,li {
   float: left;
   margin: 0 20px;
 }
 .header-logo {
   width: 160px;
   height: 40px;
   margin-top: 12px;
   margin-left: 80px;
   cursor: pointer;
 }
 .nav {
   width: 668px;
   height: 100%;
   line-height: 64px;
   font-size: 16px;
   font-weight: 400;
 }
 .nav ul {
   margin: 0;
 }

 .submenu-bg ul, li{
   margin: 0;
 }
 ::v-deep .el-menu-item, ::v-deep .el-submenu__title {
   transition: none;
 }
 ::v-deep .el-menu--horizontal>.el-menu-item.is-active {
   border: 0;
 }
 ::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
   border: 0;
 }
 ::v-deep .el-menu--horizontal>.el-menu-item.is-active::after,  ::v-deep.el-menu-item .header-title .is-active:after,  ::v-deep.submenu-bg .el-submenu .is-active::after{
    content: '';
   display: block;
   background-color: #fff;
   width: 36px;
   height: 2px;
   margin: 0 auto;
 }
  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
   display: none;
 }
 ::v-deep  .el-menu--horizontal>.el-submenu.is-active .el-submenu__title::after {
   content: '';
   display: block;
   background-color: #fff;
   width: 36px;
   height: 2px;
   margin: 0 auto;
 }
 .header-title {
   font-size: 16px;
 }
 .i18n {
   width: 130px;
   height: 100%;
   line-height: 64px;
   margin-right: 100px;
   display: flex;
 }
 ::v-deep .el-dropdown-menu__item {
   float: none!important;
 }
 ::v-deep .el-icon-arrow-down:before{
   color: #ffffff;
   font-size: 12px;
 }
 .icon-language {
   display: inline-block;
   width: 16px;
   height: 16px;
   margin-right: 10px;
   margin-top: 25px;
   vertical-align:middle
 }
 .ChannelSelected {
   display: inline-block;
 }
 .el-dropdown-link {
   cursor: pointer;
   color: #ffffff;
 }
.el-icon-arrow-down {
   font-size: 16px;
 }
</style>
<!--移动端-->
<style scoped lang="less">
.m-top {
  width: 100%;
  height: 1.3rem;
}
.m-header {
  width: 100vw;
  height: 1.3rem;
  line-height: 1.3rem;
  background-color: #102867;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem 0 .3rem;
  position: fixed;
  z-index: 99;
}
.m-header-logo {
  width: 3.7rem;
  height: 1.2rem;
}
.m-header-logo img{
  width: 100%;
  height: 100%;
}
.set {
  width: .44rem;
  height: .42rem;
}
.set img {
  width: 100%;
  height: 100%;
}
.fixed-nav {
  position: fixed;
  left: 0;
  top: 1.3rem;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}
.fixed-nav .bj {
  background: #11142C;
  opacity: .8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}
.fixed-nav .nav-content {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 102;
  width: 5.5rem;
  background-color: #102867;
  padding: 0.1rem 0.25rem;
  height: 100vh;
  overflow-y: scroll;
}
.fixed-nav .nav-content ul li {
  float: none;
}
.nav-content::-webkit-scrollbar {
  display: none;
}
.fixed-nav .nav-content .nav-item {
  margin-bottom: 2.6rem;
  width: 100%;
  color: #3b3d41;
  font-size: 1.6rem;
  font-weight: 400;
}
.m-header-title {
  font-size: 17px
}
::v-deep .nav-content .el-icon-arrow-down:before{
  color: #ffffff;
  font-size: 20px;
}

</style>
