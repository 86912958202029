<template>
  <div>
    <!--pc端-->
    <div v-if="!isMobile">
      <topNav />
      <router-view></router-view>
      <footerArea />
    </div>
    <!--移动端-->
    <div v-else class="container">
    <!--导航头部-->
      <topNav />
      <router-view></router-view>
      <footerArea />
    </div>
  </div>

</template>

<script>
import { isMobileDevice } from '../utils/detectDevice';
import topNav from './components/topNav'
import footerArea from './components/footerArea'
export default {
  name: "Layout",
  components: { topNav, footerArea },
  data() {
    return {
      isMobile: isMobileDevice()
    }
  }
}
</script>

<style scoped>

</style>