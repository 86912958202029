<template>
  <div :class="!isMobile ? 'coming-song' :'m-coming-song'">
    <div>
      <h2>敬请期待</h2>
      <p>功能还在陆续完善中,敬请期待</p>
    </div>
  </div>
</template>

<script>
import { isMobileDevice } from '../utils/detectDevice';
export default {
  name: "comingSoon",
  data() {
    return {
      isMobile: isMobileDevice()
    }
  }
}
</script>

<style scoped>
.coming-song {
  width: 100%;
  height: 360px;
  text-align: center;
  margin: 150px auto 0;
  font-size: 40px;
  font-family: AlimamaShuHeiTi;
  color: #11142C;
}
.m-coming-song {
  width: 100vw;
  height: 15vh;
  text-align: center;
  color: #11142C;
  margin: 1.5rem auto 0;
  font-size: .35rem!important;
  font-family: AlimamaShuHeiTi;
}
</style>