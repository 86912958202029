<template>
  <div>
    <div class="footer-area" v-if="!isMobile">
      <div class="service">
        <div class="gird">
          <div class="footer-logo" @click="goto('/')">
            <router-link to="/"><img src="../../assets/image/logo/logo.svg" alt="logo" class="logo"></router-link>
          </div>
          <div class="desc font-14 w100 h60">
            <p>在Crossea，我们致力于提供全面的国际贸易服务，帮助企业在</p>
            <p>全球市场中蓬勃发展。 凭借丰富的经验和深厚的行业知识，我们</p>
            <p>为客户提供量身定制的解决方案，以满足其独特的需求</p>
          </div>
          <div class="contact-information">
            客户建议及合作：xaku@nextradetech.com
          </div>
        </div>
        <div class="gird">
          <div class="footer-a" style="line-height: 75px">产品服务</div>
          <div class="nav-a font-14 w100 h60 ">
            <p style="color: #ABACB2; cursor: pointer" @click="goto('./about')">关于我们</p>
            <p style="color: #ABACB2; cursor: pointer"><router-link to="/coming-soon" target="_blank">国际贸易</router-link></p>
            <p style="color: #ABACB2; cursor: pointer"><router-link to="/coming-soon" target="_blank">市场分析</router-link></p>
            <p style="color: #ABACB2; cursor: pointer"><router-link to="/coming-soon" target="_blank">贸易履约</router-link></p>
            <p style="color: #ABACB2; cursor: pointer"><router-link to="/coming-soon" target="_blank">智慧采购</router-link></p>
            <p style="color: #ABACB2; cursor: pointer"><router-link to="/coming-soon" target="_blank">物流解决方案</router-link></p>
          </div>
        </div>
        <div class="gird">
          <div class="footer-a" style="line-height: 75px">进一步了解</div>
          <div class="nav-a font-14 w100 h60" style="color: #ABACB2; cursor: pointer" @click="goto('./about')">
            <p>关于我们</p>
          </div>
        </div>
        <div class="gird">
          <div class="footer-a" style="line-height: 75px">规则条款</div>
          <div class="nav-a font-14 w100 h60">
            <p><router-link  to="/coming-soon" target="_blank">服务条款</router-link></p>
          </div>
        </div>

      </div>
      <div class="copyright">
        <p class="mb-0">Copyright © 2023 拓发数贸（无锡）科技有限公司 All Rights Reserved. 备案号：
          <a target="_blank" :href="url">苏ICP备2023021455号</a>
        </p>
      </div>
    </div>
    <!--移動端-->
    <div v-else class="footer">
      <div class="m-serve">
        <div class="m-footer-logo" @click="goto('./')">
          <router-link to="/"><img src="../../assets/image/logo/logo.svg" alt="logo" class="logo"></router-link>
        </div>
        <div class="m-desc">
          <p>在Crossea，我们致力于提供全面的国际贸易服务，帮助企业在</p>
          <p>全球市场中蓬勃发展。 凭借丰富的经验和深厚的行业知识，我</p>
          <p>们为客户提供量身定制的解决方案，以满足其独特的需求</p>
        </div>
        <div class="m-contact-information">
          客户建议及合作：xaku@nextradetech.com
        </div>
        <div class="m-footer-gird">
          <div class="m-gird">
            <div class="m-footer-a">产品服务</div>
            <div class="alj">
              <p @click="$router.push('./about')">关于我们</p>
              <p @click="$router.push('/coming-soon')">国际贸易</p>
              <p @click="$router.push('/coming-soon')">市场分析</p>
              <p @click="$router.push('/coming-soon')">贸易履约</p>
              <p @click="$router.push('/coming-soon')">智慧采购</p>
              <p @click="$router.push('/coming-soon')">物流解决方案</p>
            </div>
          </div>
          <div class="m-gird">
            <div class="m-footer-a">进一步了解</div>
            <div class="alj" @click="$router.push('./about')">
              <p>关于我们</p>
            </div>
          </div>
          <div class="m-gird">
            <div class="m-footer-a">规则条款</div>
            <div class="alj">
              <p @click="$router.push('/coming-soon')">服务条款</p>
            </div>
          </div>
        </div>
      </div>
      <div class="m-copyright">
        <p>Copyright©2023拓发数贸（无锡）科技有限公司 All Rights Reserved.</p>
        <p style="margin-top: .1rem">
           备案号：<a target="_blank" :href="url">苏ICP备2023021455号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobileDevice } from "../../utils/detectDevice";
export default {
  name: "footerArea",
  data() {
    return {
      isMobile: isMobileDevice(),
      url: 'https://beian.miit.gov.cn/'
    }
  },
  methods: {
    goto(index) {
      this.$router.push(index)
      setTimeout(()=>{
        window.location.reload()
      },10)
    }
  }
}
</script>

<style scoped lang="less">
.footer-area {
  width: 100%;
  height: 480px;
  background-color: #11142C;
}
.service {
  width: 1280px;
  height: 420px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  .footer-a {
    font-size: 18px;
    color: white;
  }
}
.footer-logo {
  width: 300px;
  height: 75px;
}
.footer-logo img {
  width: 100%;
  height: 100%;
}
.gird {
  margin-top: 50px;
}
.gird .desc{
 margin: 30px 0 30px;
}
.gird .nav-a p {
  margin: 10px 0;
}
.gird .desc p, .gird .nav-a p a{
  color: #ABACB2;
}
.contact-information {
  font-size: 18px;
}
.copyright {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #2A2D44;
  text-align: center;
}
.copyright p, .copyright a {
  color: #ABACB2;
  font-size: 14px;
}
</style>
<style scoped>
.footer {

}
.m-serve {
  width: 100vw;
  background: #11142C;
  color: #ABACB2;
  padding: 1rem 0.5rem;
}
.m-footer-logo {
  width: 5rem;
  height: 1.5rem;
}
.m-footer-logo img {
  background-size: 100%;
}
.m-desc {
  font-size: .3rem;
}
.m-contact-information {
  color: #ffffff;
  margin-top: .3rem;
  font-size: .35rem;
}
.m-footer-gird {
  width: 100%;
  height: 3.8rem;
}
.m-gird {
  width: 33.33%;
  float: left;
}
.alj p {
  color: #ABACB2;
  font-size: .28rem;
  cursor: pointer;
  margin: .1rem 0;
}
.m-footer-a {
  color: #ffffff;
  margin-top: .5rem;
  font-size: .3rem;
  margin-bottom: .2rem;
}

.m-copyright {
  width: 100vw;
  background-color: #2A2D44;
  text-align: center;
  font-size: .24rem;
  color: #ABACB2;
  padding: .2rem 0;
}
.m-copyright a {
  color: #ABACB2;
}

</style>
