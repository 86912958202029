<template>
  <el-button @click="scrollToTop" v-if="showButton" class="back-to-top-btn">
    <div><i class="el-icon-top" style="margin-bottom: 5px; font-size: 18px;"/></div>
    <div style="font-size: 12px;">回顶部</div>
  </el-button>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      // 根据滚动位置控制按钮显示/隐藏
      this.showButton = window.scrollY > 1000;
    },
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  width: 62px;
  height: 60px;
  background-color: #153672;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 998;
}

.back-to-top-btn:hover {
  background-color: #fff;
  color: #102867;
  box-shadow: 0px 3px 6px 0px #0000000D;
  border: 1px solid rgba(16, 40, 103, 0.05);
}
</style>
