<template>
  <div id="app">
    <BackToTop v-show="!isMobile"/>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>

import BackToTop from "./components/backToTop"
import { isMobileDevice } from './utils/detectDevice';
export default {
  name: "App",
  components: { BackToTop },
  data() {
    return {
      isMobile: isMobileDevice()
    }
  }
};
</script>

<style>
</style>
