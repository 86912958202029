import Vue from "vue";
import VueRouter from "vue-router";
import Layout from '@/layout'
import comingSoon from "../components/comingSoon";


// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: '',
		component: Layout,
		redirect: 'index',
		children: [
			{
				path: '/',
				component: () => import('@/views/index'),
				name: 'index',
				meta: { title: 'CROSSEA'}
			},
			{
				path: '/transfer',
				component: () => import('@/views/transfer'),
				name: 'transfer',
				meta: { title: '跨境转运' }
			},
			{
				path: '/prophet',
				component: () => import('@/views/toSea/prophet'),
				name: 'prophet',
				meta: { title: '先知' }
			},
			{
				path: '/talents',
				component: () => import('@/views/toSea/talents'),
				name: 'talents',
				meta: { title: '雏鹰人才' }
			},
			{
				path: '/corotong',
				component: () => import('@/views/toSea/corotong'),
				name: 'corotong',
				meta: { title: '科若通' }
			},
			{
				path: '/pilotage-plan',
				component: () => import('@/views/toSea/pilotagePlan'),
				name: 'pilotage-plan',
				meta: { title: '领航计划' }
			},
			{
				path: '/cooperation-channel',
				component: () => import('@/views/cooperationChannel'),
				name: 'cooperation-channel',
				meta: { title: '渠道合作' }
			},
			{
				path: "/about",
				name: "about",
				component: () => import('@/views/about'),
				meta: {
					title: "关于我们",
				},
			},
			{
				path: "/contact",
				name: "contact",
				component: () => import('@/views/contact'),
				meta: {
					title: "联系我们",
				},
			},
		]
	},
	{
		path: '/coming-soon',
		component: comingSoon,
		name: 'comingSoon',
		meta: { title: '敬请期待'}
	}
];


const router = new VueRouter({
	// mode: 'history',
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
